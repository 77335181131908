<template>
  <div class="page-content">
    <div class="body"
        v-html="pageData.body"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { usePageBySlugData } from '../use/dns-api-data'

export default {
  name: 'Page-Content',
  components: {

  },
  props: {
    
  },
  async setup() {
        

        // DEV prov
        const slug = 'omeka-s'

        const store = useStore()
        const pageData = await usePageBySlugData(slug)
        const pageTitle = pageData.title

        const setHeaderTitle = store.commit('setHeaderTitle', pageTitle)
      
      return {
        setHeaderTitle,
        // DNS
        pageData
      }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.clickable {
  cursor: pointer;
}

.page-content {
  background-color: #fff;
  margin: 0 auto 4rem;
  max-width: 54rem;
    h2 {
        font-size: 1.8rem;
        margin: 1rem 0 1.2rem;
    }
    h3 {
        font-size: 1.4rem;
        margin: .8rem 0 1rem;
    }
    h4 {
        font-size: 1.2rem;
        font-weight: normal;
        margin: .8rem 0 .8rem;
    }
    p, ul {
            margin: .5rem 0;
    }
    ul {
        margin-left: 1rem;
    }
  
}
</style>
